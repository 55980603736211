<template>
  <div class="col-xl-4" style="cursor: pointer">
    <div class="card p-1">
      <div class="row">
        <div class="col-lg-5" @click="showDetail">
          <div>
            <img
              :src="candidate.candidate.imagesMap.imageportrait.url"
              class="card-img"
              alt="img"
            />
          </div>
        </div>

        <div class="col-lg-7 p-2">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 10px;
            "
          >
            <h3 @click="showDetail">{{ candidate.candidate.name }}</h3>
            <div style="color: #07693a; font-size: 1.2em">
              <strong>{{ rang }}</strong>
            </div>
          </div>
          <h5
            @click="showDetail"
            class="text-muted font-size-15"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            "
          >
            {{ candidate.candidate.description }}
          </h5>
          <div style="display: flex; justify-content: space-between">
            <div style="text-align: center" @click="showDetail">
              <div>
                <i class="mdi mdi-hand-right align-middle text-muted me-1"></i
                >votes
              </div>
              <div>
                {{ candidateUser.voteNumber.length }}
              </div>
            </div>
            <div style="margin-right: 10px">
              <b-dropdown
                class="card-drop"
                variant="white"
                menu-class="dropdown-menu-end"
                right
                toggle-class="p-0"
              >
                <template v-slot:button-content>
                  <span
                    ><i class="mdi mdi-dots-horizontal font-size-18"></i
                  ></span>
                </template>
                <b-dropdown-item @click="showUpdateModal()">{{
                  $t("pages.videos.actions.modifier")
                }}</b-dropdown-item>
                <b-dropdown-item @click="confirm()">{{
                  $t("pages.videos.actions.supprimer")
                }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal
    v-model="detailModal"
    id="modal-xl"
    title="Détails du candidat"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <div>
        <div class="card">
          <div class="card-body">
            <!-- <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                            </ol>
                            <div class="carousel-inner" role="listbox">
                                <div class="carousel-item active">
                                    <img class="d-block img-fluid" :src="candidate.imagesMap.imagepaysage.url"
                                        alt="First slide">
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block img-fluid" :src="candidate.imagesMap.imageportrait.url"
                                        alt="Second slide">
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div> -->

            <img
              class="d-block img-fluid"
              :src="candidate.candidate.imagesMap.imagepaysage.url"
            />
            <div class="mt-1">
              <div style="text-align: justify">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <h2>{{ candidate.candidate.name }}</h2>
                  </div>
                  <div>
                    <h2 class="tags-input_tag c2play-primary btn">
                      {{ candidateUser.voteNumber.length }}
                    </h2>
                  </div>
                </div>
                <br />
                <div class="flex-grow-1">
                  <h5 class="text-muted fw-medium mb-2">
                    Liste de catégorie du candidat
                  </h5>
                  <h4 class="mb-0">
                    <span
                      v-for="categorie in candidate.candidate.categories"
                      :key="categorie.id"
                      class="tags-input_tag c2play-primary"
                      >{{ categorie.name }}</span
                    >
                  </h4>
                </div>
                <br /><br /><br /><br />
                <h5>
                  <strong>{{ candidate.candidate.description }}</strong>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>

  <loaderProcessVue
    :title="titre"
    :progress="progress"
    :visible="process"
  ></loaderProcessVue>

  <b-modal
    v-model="updateModal"
    id="modal-xl"
    size="xl"
    title="Modifier le candidat"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
        {{ authError }}
      </b-alert>
      <form>
        <div class="row">
          <div class="form-group col-lg-12 mb-3">
            <label for="projectname" class="col-form-label"
              >Nom du candidat</label
            >
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                placeholder="Entrez le nom du candidat"
                v-model="candidateUser.name"
              />
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group col-lg-6">
            <label for="projectbudget" class="col-form-label"
              >Image Portrait</label
            >
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="imgVideoSelect($event)"
                class="form-control"
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label for="projectbudget" class="col-form-label"
              >Image plateau (Paysage)</label
            >
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="imgVideoSelectPaysage($event)"
                class="form-control"
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="projectbudget" class="col-form-label">Description</label>

          <div class="col-lg-12">
            <textarea
              id="projectdesc"
              class="form-control"
              rows="3"
              :placeholder="
                $t('pages.programme.formulaire.description.placeholder')
              "
              v-model="candidateUser.description"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div
            class="col-lg-3"
            v-for="categorie in categories"
            :key="categorie.id"
          >
            <b-form-checkbox
              v-model="idCategorie"
              name="checkbox-1"
              :value="categorie.id"
            >
              <div>
                {{ categorie.name }}
              </div>
            </b-form-checkbox>
          </div>
        </div>
      </form>
      <div class="row justify-content-end">
        <div class="col-lg-10" style="margin-top: 10px">
          <button
            type="submit"
            class="btn c2play-primary"
            style="position: relative; left: 40%"
            @click="updateCandidate"
          >
            {{ $t("updateButton") }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";

export default {
  components: {
    loaderProcessVue,
  },

  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      detailModal: false,
      updateModal: false,
      candidateUser: {
        name: "",
        description: "",
        voteNumber: [],
      },
      nombreDeVote: [],
    };
  },

  props: {
    candidate: {
      type: Object,
      required: true,
    },
    statutAward: String,
    rang: String,
  },

  methods: {
    showDetail() {
      this.detailModal = true;
    },
    showUpdateModal() {
      this.updateModal = true;
    },
    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    updateCandidate() {
      this.process = true;
      this.updateModal = false;
      this.titre = "Modification du candidat";
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      if (this.imgSelect) {
        this.process = true;
        this.updateModal = false;
        Api.putFormData(
          `/voting/rest/candidat/img-portrait/${this.candidate.candidate.id}`,
          {
            imageportrait: this.imgSelect,
          },
          { onUploadProgress }
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Image Modifié avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      if (this.imgPaysage) {
        Api.putFormData(
          `/voting/rest/candidat/img-paysage/${this.candidate.candidate.id}`,
          {
            imagepaysage: this.imgPaysage,
          },
          { onUploadProgress }
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Image Modifiée avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      Api.putFormData(
        `/voting/rest/candidat/${this.candidate.candidate.id}`,
        {
          description: this.candidateUser.description,
          name: this.candidateUser.name,
        },
        { onUploadProgress }
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Candidat Modifié avec succès", "success");
          console.log(response);
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirm() {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteCandidate();
        }
      });
    },

    deleteCandidate() {
      Api.delete(`/voting/rest/candidat/delete-candidat/${this.candidate.candidate.id}`)
        .then((response) => {
          this.channels = response.data.content;
          console.log(this.channels);
          Swal.fire("Deleted!", "Le candidat a bien été supprimé", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },

  mounted() {
    this.candidateUser.description = this.candidate.candidate.description;
    this.candidateUser.name = this.candidate.candidate.name;
    this.candidateUser.voteNumber = this.candidate.voteUser;
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 135px;
  width: 100%;
  border-radius: 6px;
}
</style>
